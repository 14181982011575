
<template>
  <div class="px-4 py-4 text-center bg-black text-white">
    <img :src="product.image" :alt="product.title" class="img-fluid mb-5 hover-scale-105 w-50">
    <h2 class="mb-4">{{ product.title_long }}</h2>
    <div class="row mb-3 justify-content-center text-center">
      <div class="col-12 col-md-8">
        <p class="fs-5 fw-normal mb-3">{{ product.description }}</p>
        <a href="#modal-buy" class="btn btn-outline-primary btn-lg" aria-current="page" data-bs-toggle="modal" data-bs-target="#modal-buy">Купить</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
}
</script>
