<template>
  <!-- stick top section -->
  <div class="container-fluid sticky-top ">
    <div class="row">    
      <section id="product-item" class="product-item-top-menu container d-flex flex-wrap py-3 mb-0 bg-dark">
        
        <a href="/" class="me-auto mb-md-0 me-md-auto d-flex align-items-center text-decoration-none" style="max-width:70%;">
          <img :src="'assets/logo.svg'" alt=""> 
          <span class="fs-6 text-white px-2 text-truncate">{{product.title}}</span>
        </a>

        <a href="#modal-buy" class="btn btn-outline-primary btn-sm" aria-current="page" data-bs-toggle="modal" data-bs-target="#modal-buy">Купить</a>

      </section>
    </div>
  </div>
  <!-- stick top section end -->
  
  <!-- Modal buy window -->
  <div class="modal fade" id="modal-buy" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="modal-buy" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content rounded-4 shadow">
      <div class="modal-body p-4">
        <h3 class="fw-bold mb-5">Где купить {{ product.title }}?</h3>
        <ul class="d-grid gap-4 my-1 list-unstyled">
          <li class="d-flex gap-4">
            <a :href="product.link_market" target="_blank">
              <img :src="'assets/yandex-market.png'" alt="" class="rounded bi text-muted flex-shrink-0" width="48" height="48">
            </a>            
            <a :href="product.link_market" class="d-block text-decoration-none text-dark">
              <h5 class="mb-0">Яндекс.Маркет</h5>
              Купить на Яндекс.Маркете
            </a>
          </li>
          <li class="d-flex gap-4">
            <a :href="product.link_market" target="_blank">
              <img :src="'assets/ozon.png'" alt="" class="rounded bi text-muted flex-shrink-0" width="48" height="48">
            </a>
            <a :href="product.link_ozon" class="d-block text-decoration-none text-dark">
              <h5 class="mb-0">OZON</h5>
              Купить на OZON
            </a>
          </li>
          <li class="d-flex gap-4">
            <a :href="product.link_wildberries" class="disabled">
              <img :src="'assets/wildberries.png'" alt="" class="img-muted rounded bi text-muted flex-shrink-0" width="48" height="48">
            </a>
            <a :href="product.link_wildberries" class="disabled d-block text-decoration-none text-dark text-muted">
              <h5 class="mb-0">Wildberries</h5>
              Скоро на WB! 
            </a>
          </li>
        </ul>
        <button type="button" class="btn btn-lg btn-primary mt-5 w-100" data-bs-dismiss="modal">Отлично, Спасибо!</button>
      </div>
    </div>
  </div>
</div>

</template>

<script>
export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
}
</script> 