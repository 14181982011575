import './scss/styles.scss'

import { createApp } from 'vue'
import App from './App.vue'
//import VueMeta from 'vue-meta'
import router from './router'
import "bootstrap"
import { BootstrapIconsPlugin } from 'bootstrap-icons-vue';


createApp(App)
  .use(router)
  // .use(VueMeta, {
  //   refreshOnceOnNavigation: true
  // })  
  .use(BootstrapIconsPlugin)
  .mount('#app')