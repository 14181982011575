<template>
<div class="container-fluid">
  <div class="row">
    <div class="col-md-6 p-1">
      <ProductCard :product="findProduct('socks-long')" class="bg-black" />
    </div>
    <div class="col-md-6 p-1">
      <ProductCard :product="findProduct('socks-short')" class="bg-black"/>
    </div>
    <div class="col-md-12 p-1">
      <ProductCard :product="findProduct('tights')" class="bg-light px-4 py-5" />
    </div>
  </div>
</div>
</template>

<script>
import { products } from '../data.js'
import ProductCard from './ProductCard.vue'

export default {
  components: {
    ProductCard,
  },
  mounted() {
    document.title = 'BSKTBLL° — LL°';
  },
  data() {
    return {
      products: products
    }
  },
  methods: {
    findProduct(id) {
      return this.products.find(product => product.id === id);
    }
  },
}
</script>
