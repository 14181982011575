<template>
  <div class="card product-card p-4" :id="product.id">
    
    <div class="card-body" :class="product.position">
      <h2 class="card-title mb-3" :class="product.text">{{ product.title }}</h2>
      
      <ul class="product-link list-inline list-unstyled mb-4">
        <li class="list-inline-item pr-2"><router-link :to="{ path: product.link, hash: '#spec' }" class="stretched-link">Подробнее</router-link> ≻</li>
        <li class="list-inline-item px-2"><router-link :to="{ path: product.link, hash: '#buy' }">Купить</router-link> ≻</li>
      </ul>

    </div>
    <div class="product-card-image">
      <router-link :to="{path: product.link, hash: '#buy'}">
        <img :src="product.image" :alt="product.title" class="card-img-bottom">
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
}
</script>