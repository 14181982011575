import { createRouter, createWebHistory } from 'vue-router'

import PageMain from './components/PageMain.vue'
import ProductSocksLong from './components/PageSocksLong.vue'
import ProductSocksShort from './components/PageSocksShort.vue'
import ProductTights from './components/PageTights.vue'
const routes = [
  { path: '/', component: PageMain },
  { path: '/socks-short', component: ProductSocksShort },
  { path: '/socks-long', component: ProductSocksLong },
  { path: '/tights', component: ProductTights },
  // добавьте здесь больше маршрутов
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router