<template>
<section class="p-4 text-center container-fluid bg-dark">
  <div class="row pt-3">
    <div class="col g-2 g-md-4" v-for="(column, columnIndex) in galleryColumns" :key="'column-' + columnIndex">
      <img 
        v-for="(image, imageIndex) in column" 
        :key="'image-' + imageIndex" 
        :src="'assets/products/' + image" 
        :alt="`${product.title}`" 
        class="w-100 shadow-1-strong rounded mb-2 mb-md-4 hover-scale-105"
        data-fancybox="gallery"
      >
    </div>
  </div>   
</section>
</template>

<script>
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  computed: {
    galleryColumns() {
      const columns = [[], [], []];
      for (let i = 0; i < this.product.gallery.length; i++) {
        columns[i % 3].push(this.product.gallery[i]);
      }
      return columns;
    },
  },  
  mounted() {
    Fancybox.bind(this.$refs.container, '[data-fancybox]', {
      ...(this.options || {}),
    });
  },
  updated() {
    Fancybox.unbind(this.$refs.container);
    Fancybox.close();

    Fancybox.bind(this.$refs.container, '[data-fancybox]', {
      ...(this.options || {}),
    });
  },
  unmounted() {
    Fancybox.destroy();
  },  
}
</script> 