<template>
  <section class="bg-black text-white text-center py-5">
    <h3>Рекомендации по уходу</h3>
    <p>Чтобы наши вещи долго радовали вас своим внешним видом, просто следуйте простым советам по уходу.</p>
  
    <div class="row pt-4 row-cols-2 row-cols-md-6 row-cols-lg-6 g-4 justify-content-center">
      <div v-for="item in productCareItems" :key="item.id" class="col">
        <div class="card-care text-center hover-translate-y-n10">
          <div class="card-care-icon">
            <img :src="item.icon" :alt="item.title" />
          </div>
          <div class="card-body pb-0">
            <p class="card-text fw-light lh-sm text-italic" v-html="item.title"></p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      care: [
        {
          id: 'wash-30',
          title: 'Стирать при&nbsp;t°<br />не&nbsp;выше&nbsp;30°',
          icon: 'assets/icons/icon-wash-30.svg'
        },
        {
          id: 'no-iron',
          title: 'Не<br />гладить',
          icon: 'assets/icons/icon-no-iron.svg'
        },
        {
          id: 'no-bleach',
          title: 'Не<br />отбеливать',
          icon: 'assets/icons/icon-no-bleach.svg'
        },
        {
          id: 'no-auto',
          title: 'Нельзя выжимать и&nbsp;сушить в&nbsp;машинке',
          icon: 'assets/icons/icon-no-auto.svg'
        },        
        {
          id: 'no-laundry',
          title: 'Химчистка<br />запрещена',
          icon: 'assets/icons/icon-no-laundry.svg'
        },
        {
          id: 'dry-low',
          title: 'Сушить при низкой температуре',
          icon: 'assets/icons/icon-dry-low.svg'
        },
        {
          id: 'dry-room',
          title: 'Сушить при комнатной температуре',
          icon: 'assets/icons/icon-dry-room.svg'
        },
      ]
    };
  },
  computed: {
    productCareItems() {
      return this.care.filter(item => this.product.care.includes(item.id));
    }
  }
}
</script> 