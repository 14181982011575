<template>
  
  <ProductItemTop :product="findProduct('tights')" />
  <ProductItemHero :product="findProduct('tights')" class="pt-0" />
  <ProductItemGallery :product="findProduct('tights')" class="pt-0" />

  <section class="bg-dark text-white">
    <div class="container px-4 py-5 ">
      <div class="row row-cols-1 row-cols-sm-2 g-6">
        <div class="feature col d-flex flex-column gap-2">     
          <div class="feature-icon d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-2 mb-3">
            <b-icon-layers-half class="fs-1"/>
          </div> 
          <h4 class="fw-semibold mb-0">Прочность и легкость</h4>
          <p class="text-body-secondary">Изготовлены из компрессионной ткани, которая сочетает в себе прочность и легкость, обеспечивая уникальные свойства.</p>
        </div>

        <div class="col d-flex flex-column gap-2">
          <div class="feature-icon d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-2 mb-3">
            <b-icon-heart-pulse class="fs-1"/>
          </div>              
          <h4 class="fw-semibold mb-0">Снижение риска травм</h4>
          <p class="text-body-secondary">Наколенники, встроенные в наши компрессионные тайтсы, специально разработаны для снижения риска травматизма колен во время тренировок и игр.</p>
        </div>

        <div class="col d-flex flex-column gap-2">
          <div class="feature-icon d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-2 mb-3">
            <b-icon-droplet class="fs-1"/>
          </div>
          <h4 class="fw-semibold mb-0">Эффективно отводят влагу</h4>
          <p class="text-body-secondary">Активно отводят влагу от кожи, обеспечивая вам комфорт во время тренировок и игр.</p>
        </div>

        <div class="col d-flex flex-column gap-2">
          <div class="feature-icon d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-2 mb-3">
            <b-icon-thermometer-snow class="fs-1"/>
          </div>
          <h4 class="fw-semibold mb-0">Оптимальная терморегуляция</h4>
          <p class="text-body-secondary">Специализированный материал эффективно регулирует температуру вашего тела, обеспечивая комфорт во время активности.</p>
        </div>
      </div>
    </div>
  </section>

  <ProductItemCare :product="findProduct('tights')" class="mb-1"/>
  <ProductItemBuy :product="findProduct('tights')" />

  </template>
  
  <script>
  import { products } from '../data.js'
  import ProductItemTop from './block/ProductItemTop.vue'
  import ProductItemCare from './block/ProductItemCare.vue'
  import ProductItemHero from './block/ProductItemHero.vue'
  import ProductItemBuy from './block/ProductItemBuy.vue'
  import ProductItemGallery from './block/ProductItemGallery.vue'

  export default {
    components: {
      ProductItemTop,
      ProductItemHero,
      ProductItemCare,
      ProductItemBuy,
      ProductItemGallery,
    },
    mounted() {
      document.title = 'Компрессионные тайтсы с наколенниками — LL°';
      this.isLoaded = true;
    },
    data() {
      return {
        products: products
      }
    },
    methods: {
      findProduct(id) {
        return this.products.find(product => product.id === id);
      }
    },
    
  }
  </script>
  