<template>
<ProductItemTop :product="findProduct('socks-long')" />
<ProductItemHero :product="findProduct('socks-long')" />
<ProductItemGallery :product="findProduct('socks-long')" />

<section class="bg-dark text-white">
  <div class="container px-4 py-5 ">
    <div class="row row-cols-1 row-cols-sm-2 g-6">

      <div class="col d-flex flex-column gap-2">     
        <div class="feature-icon d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-2 mb-3">
          <b-icon-heart-pulse class="fs-1"/>
        </div> 
        <h4 class="fw-semibold mb-0">Идеальная форма</h4>
        <p class="text-body-secondary">Носки имеют анатомически правильную форму для левой и правой стопы, что обеспечивает идеальную посадку и комфорт во время носки.</p>
      </div>

      <div class="col d-flex flex-column gap-2">
        <div class="feature-icon d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-2 mb-3">
          <b-icon-bricks class="fs-1"/>
        </div>              
        <h4 class="fw-semibold mb-0">Усиление и комфорт</h4>
        <p class="text-body-secondary">Носки оснащены усиленной пяткой и мыском для дополнительной прочности, а также плоским швом, обеспечивающим максимальный комфорт при носке.</p>
      </div>

      <div class="col d-flex flex-column gap-2">
        <div class="feature-icon d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-2 mb-3">
          <b-icon-align-center class="fs-1"/>
        </div>
        <h4 class="fw-semibold mb-0">Система поддержки</h4>
        <p class="text-body-secondary">С системой динамической поддержки в средней части стопы, эти носки обеспечивают комфорт и стабильность во время движения, облегчая нагрузку на стопу.</p>
      </div>

      <div class="col d-flex flex-column gap-2">
        <div class="feature-icon d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-2 mb-3">
          <b-icon-droplet class="fs-1"/>
        </div>
        <h4 class="fw-semibold mb-0">Отвод влаги</h4>
        <p class="text-body-secondary">Наши носки используют специальную технологию вязки, которая обеспечивает быстрый отвод влаги и помогает сохранять комфорт во время тренировок.</p>
      </div>
    </div>
  </div>
</section>

<ProductItemCare :product="findProduct('socks-long')" />
<ProductItemBuy :product="findProduct('socks-long')" />
</template>

<script>
import { products } from '../data.js'
import ProductItemTop from './block/ProductItemTop.vue'
import ProductItemGallery from './block/ProductItemGallery.vue'
import ProductItemHero from './block/ProductItemHero.vue'
import ProductItemCare from './block/ProductItemCare.vue'
import ProductItemBuy from './block/ProductItemBuy.vue'

export default {
  components: {
    ProductItemTop,
    ProductItemHero,
    ProductItemGallery,
    ProductItemCare,
    ProductItemBuy,
  },
  mounted() {
    document.title = 'Длинные спортивные носки — LL°';
  },
  data() {
    return {
      products: products
    }
  },
  methods: {
    findProduct(id) {
      return this.products.find(product => product.id === id);
    }
  }
}
</script>
