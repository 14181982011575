// data.js
export let products = [
  {
    id: 'socks-long',
    title: 'Длинные носки',
    title_short: 'Длинные носки',
    title_long: 'Длинные спортивные носки',
    description: 'Анатомическая форма наших длинных спортивных носков обеспечивает идеальную посадку. Усиленная пятка и мысок добавляют прочности, плоский шов гарантирует комфорт. Система поддержки стабилизирует стопу, а технология вязки быстро отводит влагу.',
    buy_description: 'Наша модель длинных спортивных носков обеспечивает идеальную анатомическую посадку. Они усилены для прочности и комфорта, обладают системой поддержки для стабильности и технологией отвода влаги.',
    image: 'assets/products/socks-long.webp',
    link: 'socks-long',
    card_class: 'bg-black',
    text: 'text-white',
    position: 'text-center',
    link_ozon: 'https://ozon.ru/t/b4ezBYq',
    link_market: 'https://market.yandex.ru/store--bsktbll/product/1828194656?businessId=50278553&sku=101979586273&utm_source=qr.bsktbll.ru&utm_compaign=bsktbll-socks-long',
    link_wildberries: '#',
    care: ['wash-30', 'no-iron', 'dry-room'],
    gallery: ['socks-long-1.webp','socks-long-2.webp','socks-long-3.webp','socks-long-4.webp','socks-long-5.webp','socks-long-6.webp']
  },
  {
    id: 'socks-short',
    title: 'Короткие носки',
    title_short: 'Короткие носки',
    title_long: 'Короткие спортивные носки',
    description: 'Наши короткие спортивные носки гарантируют комфорт и поддержку ваших стоп во время тренировок. Они анатомически подходят для каждой ноги, обеспечивают усиление на пятке и мыске, а также оснащены технологией быстрого отвода влаги.',
    buy_description: 'Наши короткие спортивные носки гарантируют комфорт и поддержку ваших стоп во время тренировок. Они анатомически подходят для каждой ноги, обеспечивают усиление на пятке и мыске, а также оснащены технологией быстрого отвода влаги.',
    image: 'assets/products/socks-short.webp',
    link: 'socks-short',
    card_class: 'bg-black',
    text: 'text-white',
    position: 'text-center',
    link_ozon: 'https://ozon.ru/t/dw5z5Ql',
    link_market: 'https://market.yandex.ru/store--bsktbll/product/1828208367?businessId=50278553&sku=101979762014&utm_source=qr.bsktbll.ru&utm_compaign=bsktbll-socks-short',
    link_wildberries: '#',
    care: ['wash-30', 'no-iron', 'dry-room'],
    gallery: ['socks-short-1.webp','socks-short-2.webp','socks-short-3.webp','socks-short-4.webp','socks-short-5.webp','socks-short-6.webp']
  },
  {
    id: 'tights',
    title: 'Тайтсы с наколенниками',
    title_short: 'Тайтсы с наколенниками',
    title_long: 'Компрессионные тайтсы с наколенниками',
    description: 'Наши компрессионные тайтсы с наколенниками — идеальное сочетание легкости, прочности и комфорта. Они обеспечивают защиту колен и отличную терморегуляцию во время активности.',
    buy_description: 'Оптимизируйте тренировки с нашими компрессионными тайтсами. Встроенные наколенники защищают, а специальная ткань обеспечивает комфорт и поддержку.',
    image: 'assets/products/tights.webp',
    link: 'tights',
    card_class: 'bg-light',
    text: '',
    position: 'text-center',
    link_ozon: 'https://ozon.ru/t/8r9X9wb',
    link_market: 'https://market.yandex.ru/store--bsktbll/product/1809298254?businessId=50278553&sku=101910498283&utm_source=qr.bsktbll.ru&utm_compaign=bsktbll-tights',
    link_wildberries: '#',
    care: ['wash-30', 'no-iron', 'no-bleach','no-auto','no-laundry'],
    gallery: ['tights-gallery-1.webp','tights-gallery-2.webp','tights-gallery-3.webp','tights-gallery-4.webp','tights-gallery-5.webp','tights-gallery-6.webp']
  },
  // ...
];