<template>
  <!-- stick top section -->
  <section class="bg-black text-white text-center py-5 container-fluid">
    <h2 class="fs-3 mb-3">{{ product.title }}</h2>
    <div class="row mb-3 justify-content-center text-center">
      <div class="col-12 col-md-8">
        <p class="fs-5 fw-normal mb-3">{{ product.buy_description }}</p>
        <a href="#modal-buy" class="btn btn-outline-primary btn-lg" aria-current="page" data-bs-toggle="modal" data-bs-target="#modal-buy">Купить</a>
      </div>
    </div>
  </section>
  <!-- stick top section end -->
</template>

<script>
export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
}
</script> 