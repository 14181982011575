<template>
  <header class="d-flex justify-content-center align-items-center bg-black py-4">
    <router-link :to="{ path: '/' }">
      <img :src="'assets/logo.svg'" alt="">
    </router-link>
  </header>
  <div id="app">
    <router-view />
  </div>
  <footer class="d-flex bg-black py-4 text-white mt-1">
    <div class="container">
      <div class="row">
        <a href="/" class="me-auto mb-md-0 me-md-auto d-flex align-items-center">
          <img :src="'assets/logo.svg'" alt="">
        </a>        
      </div>
    </div>
  </footer>
</template>